import React from "react"
import Layout from "../components/Layout"
import Wrapper from "../components/Wrapper"

const User = () => {
  console.log(`User (pages/user)`)
  return (
  <Wrapper>

  </Wrapper>
)
}

export default User